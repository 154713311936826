.total-chat-container {
    margin: 1rem 0;
    height: 100%;
}
.chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    min-width: 300px;
    height: 80vh;
    margin: 0px auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}
.chat-container .back-and-onlineuser {
    background-color: #f9f9f9;
    padding-right: 1rem;
}

.chat-container .message-bubble {
    font-family: Dovemayo_gothic;
    font-weight: 500;
}

.chat-header {
    background-color: #f9f9f9;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.chat-header >  .status {
    margin: 0;
    margin-left: 1rem;
    text-align: center;
    font-family: Dovemayo_gothic;
}
.chat-header >  .friend-btn {
    margin: 0;
    text-align: end;
    margin-left: auto;
}
.chat-messages {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;
    height: 200px;
    overflow-y: auto;
    padding: 10px;
    background: #fafafa;
    -webkit-overflow-scrolling: touch;
}

.chat-input {
    padding: 10px;
    background: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-input input {
    flex-grow: 1;
    margin-right: 5px;
    margin-left: 5px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    display: flex;
    justify-content: center;
}

.chat-input button {
    padding: 10px 10px;
    background-color: #F07489;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    margin: 0 .1rem 0 .1rem;
}

.chat-input button:hover {
    background-color: pink;
}

/* 메시지 말풍선 스타일 */
.message-bubble {
    display: block;
    padding: 8px 15px;
    border-radius: 20px;
    margin-bottom: 1.5rem;
    display: inline-block;
    max-width: 80%;
}

/* 내 메시지 스타일 */
.message-bubble.my-message {
    background-color: #dcf8c6;
    margin-left: auto;
    border-bottom-right-radius: 0;
}

/* 상대방 메시지 스타일 */
.message-bubble.their-message {
    background-color: #ffffff;
    margin-right: auto;
    border-bottom-left-radius: 0;
}

/* 기존 스타일 유지... */

.start-chat-button {
    padding: 10px 20px;
    width: 100%; /* 버튼을 전체 너비로 확장 */
    background-color: #F07489;
    border: none;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.start-chat-button:hover {
    background-color: pink;
}

.friend-request-box {
    display: flex;
    justify-content: center; /* 요소들을 가운데 정렬합니다. */
    align-items: center; /* 요소들을 세로 방향으로 중앙 정렬합니다. */
    gap: 20px; /* 버튼 사이의 간격을 설정합니다. */
}

.friend-request-box > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.friend-request-box p {
    margin: 0; /* 기본 마진을 제거합니다. */
    font-size: 1rem; /* 폰트 크기를 조정합니다. */
}
.friend-request-box > div > p:first-child {
    margin: 0 .8rem;
    font-size: 1rem;
}

.friend-request-box button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
    padding: 5px;
    border-radius: 5px;
}

.friend-request-box img {
    width: 24px; /* 이미지 크기를 설정합니다. */
    height: 24px; /* 이미지 크기를 설정합니다. */
}
.friend-request-box button:hover {
    background-color: pink;
}

.temp-message {
    position: fixed; /* 팝업을 화면에 고정 */
    top: 50%; /* 상단에서부터의 위치 */
    left: 50%; /* 왼쪽에서부터의 위치 */
    transform: translate(-50%, -50%); /* 정확한 중앙 위치 조정 */
    z-index: 1000; /* 다른 요소들 위에 표시 */
    background-color: rgba(0, 0, 0, 0.7); /* 반투명 검정색 배경 */
    color: #fff; /* 텍스트 색상 */
    padding: 20px 40px; /* 내부 여백 */
    border-radius: 5px; /* 모서리 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    font-size: .85rem; /* 폰트 크기 */
    text-align: center; /* 텍스트 중앙 정렬 */
    max-width: 80%; /* 최대 너비 설정 */
    word-wrap: break-word; /* 긴 텍스트 처리 */
}

.first-message {
    font-size: 1rem;
    margin: .5rem .8rem;
    font-family: Dovemayo_gothic;
    user-select: none;
}

/* DM.css */
.chat-container.dm {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .total-chat-container {
        margin-top: 0;
        margin-bottom: 0;
        height: calc(var(--vh, 1vh) * 100 - 55px);
    }
    .chat-container {
        height: 100%;/*테스트: 1px 더하면 스크롤되지않을까*/
        margin-bottom: 0;
    }
    .chat-messages {
        /* chat-messages 영역이 flex-grow를 사용하여 남은 공간을 채우도록 합니다. */
        flex-grow: 1;
        overflow-y: scroll; /* 이 영역에서만 스크롤이 가능하도록 합니다. */
    }
    .chat-input button:hover {
        background-color: #F07489;
    }
    .chat-container .back-and-onlineuser .online-user{
        margin-right: 0;
    }
}
@media (max-width: 400px) {
    .first-message {
        font-size: 0.85rem;
    }
    .chat-header >  .status {
        font-size: .9rem;
    }
    .chat-header {
        padding: 5px;
    }
    .chat-header >  .friend-btn > button {
        font-size: .75rem;
        padding: 6px 8px;
    }
    .message-bubble {
        font-size: .85rem;
        margin-bottom: .6rem;
    }
    .chat-input input {
        font-size: 0.85rem;
        padding: 9px;
    }
    .chat-input button {
        padding: 8px 10px!important;
        font-size: 0.85rem;
    }
    .chat-messages {
        /*justify-content: flex-end;*/
        
        flex-grow: 1;
        overflow-y: scroll;
    }

}

.loading {
    background-color: #f9f9f9;
    text-align: center;
    font-size: .9rem;
}

.chat-messages .sender_nick{
    font-size: .8rem;
    color: #888;
    margin-bottom: .5rem;
}

.my-message .sender_school {
    color: #fff;
    border-radius: 15px;
    padding: 2px 5px;
    background-color: #0a5c0a;
    margin-left: 5px;
}

.their-message .sender_school {
    color: #fff;
    border-radius: 15px;
    padding: 2px 5px;
    background-color: #F07489;
    margin-left: 5px;
}
.participants {
    margin-left: auto;
    font-size: .8rem;
    color: #888;
    margin-bottom: .5rem;
}

.participants p {
    padding-right: 10px;
}

.groupchat-name {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    padding: 10px;
}