.video-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: auto;
}

.fullscreen-image {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 768px) {
  .fullscreen-video {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: black;
  }

  .fullscreen-video video, .fullscreen-video .fullscreen-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.joystick-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 100px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  z-index: 2000; /* 조이스틱이 비디오 위에 보이도록 z-index를 설정합니다. */
}

.joystick {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: relative;
}

.button-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.control-button {
  width: 14rem;
  height: 4rem;
  background-color: rgba(0, 153, 255, 0.7);
  color: white;
  font-size: 1.1rem;
  border: none;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
