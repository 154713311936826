/* FriendListForm.css */
.friendListForm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem auto;
    padding: 10px;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 70%;
}
.friendListForm-container > .back-btn {
    margin-right: auto;
}

.friendList {
    list-style-type: none;
    padding: 0;
    width: 100%;
}

.friendList > li {
    margin-bottom: 1rem;
}

.friendItem {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    margin: 0 1rem;
    padding-bottom: 15px;
    border-bottom: solid 1px #eee;
}
.friendItem .friendManage {
    margin-left: auto;
}
.friendItem .friendManage > .icon {
    height: 1.8rem;
    padding: .5rem;
    margin-right: 1px;
    border-radius: .5rem;
}
.friendItem .friendManage > .icon:hover {
    background-color: pink;
    transition: .5s;
}

.friendInfo {
    margin-left: 15px;
    font-family: Dovemayo_gothic;
}

.friendNickname, .friendBio {
    display: block; /* Makes bio appear below the nickname */
}
.friendNickname {
    font-size: 1rem;
    font-weight: bold;
    color: #2c3e50;
}
.friendSchool {
    font-size: 1rem;
    color: #2c3e50;
}

.friendBio {
    font-size: 0.8rem;
    color: #7f8c8d;
    margin-top: 5px;
    margin-bottom: .3rem;
    max-width: 30rem;
}

.last-message {
    display: inline-block; /* 내부 콘텐츠 크기에 맞춰 너비 조정 */
    font-size: 1rem;
    color: #2C3E50;
    padding: 5px 10px;
    background-color: #dcf8c6;
    border-radius: .5rem;
    border-bottom-left-radius: 0;
}

.friendProfilePic {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%; /* Makes the profile picture circular */
}

.friendManage-fold {
    display: none;
}

.unreadDot {
    display: inline-block;
    margin-left: 5px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: pink;
    color: black;
    font-size: .9rem;
    font-weight: 300;
    text-align: center;
}

/* 깜빡이는 애니메이션 */
@keyframes blinkAnimation {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@media (max-width: 968px) {
    .friendBio {
        font-size: 0.8rem;
        width: 19rem;
    }
}

@media (max-width: 768px) {
    .friendListForm-container {
        width: 95%;
        box-shadow: none;
        margin-top: 0;
    }
    .friendItem {
        margin: 0;
    }
    .friendItem .friendManage > .icon {
        height: 1.8rem;
    }
    .friendBio {
        font-size: 0.8rem;
        width: 20rem;
    }
}

@media (max-width: 600px) {
    .friendNickname {
        font-size: .9rem;
    }
    .friendBio {
        font-size: 0.8rem;
        width: 11.9rem;
    }
    .friendSchool {
        font-size: .9rem;
        word-break: keep-all;
    }
    .friendItem > .friendManage > .icon {
        height: 1.5rem;
        padding: 4px;
    }
    .last-message {
        font-size: .9rem;
        color: #2C3E50;
        max-width: 10rem;
        border-radius: .5rem;
        padding: 5px 10px;
        background-color: #dcf8c6;
        border-bottom-left-radius: 0;
    }
}

@media (max-width: 400px) {
    .friendSchool {
        display: block;
        width: 10rem!important;
    }
}

@media (max-width: 300px) {
    .friendManage {
        display: none;
    }
    .friendManage-fold {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1rem;
        margin-left: -1.8rem;
    }
    .friendManage-fold > .icon {
        height: 1.5rem;
        padding: 4px;
    }
    .friendItem {
        display: block;
    }
    .friendItem > img {
        margin-left: 1.5rem;
    }
    .friendInfo {
        margin-left: 2rem;
    }
}