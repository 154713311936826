/* NavForm */

.nav-wrapper > .nav-logo > img {
    height: 70px; /* 로고 이미지 높이 */
    margin-right: 20px; /* 로고와 텍스트 사이의 마진 */
}
.nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: none; /* 배경색을 흰색으로 설정 */
    padding: 0 10px; /* 양쪽 패딩 설정 */
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
    z-index: 10;
}
.logo-name-s {
    font-size: .9rem !important;
    margin-left: .2rem;
}
.logo-name-l {
    font-size: 2.2rem !important;
    font-family: 'Dovemayo_gothic' !important;
    color: black;
}

.nav-wrapper > .logo-name-l {
    font-size: 2rem;
}


.nav-logo {
    display: flex;
    align-items: center;
    text-decoration: none; /* 링크의 밑줄 제거 */
}

.nav-logo img {
    height: 50px; /* 로고 이미지 높이 */
    margin-right: 10px; /* 로고와 텍스트 사이의 마진 */
}

.nav-logo div {
    display: flex;
    flex-direction: column; /* 자식 요소들을 세로로 정렬 */
}

.nav-logo span:first-child {
    font-size: 1.2em;
    color: #333333; /* 상단 텍스트 색상 */
    font-weight: bold; /* 글씨 굵기 */
}

.nav-menu {
    display: flex;
    align-items: center;
}

.nav-menu a {
    text-decoration: none; /* 링크의 밑줄 제거 */
    color: #333333; /* 링크 색상 */
    margin-right: 20px; /* 링크들 사이의 마진 */
    font-size: 1em;
}

.nav-menu a:hover {
    color: #F07489; /* 링크 호버 색상 */
    transition: .3s;
}

.notification-dot {
    display: inline-block;
    margin-left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #F07489;
    animation: blinkAnimation 2s infinite; /* 깜빡임 애니메이션 추가 */
}

@keyframes blinkAnimation {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.chat-button {
    background-color: #F07489; /* 버튼 배경 색상 */
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 15px; /* 버튼 모서리 둥글게 */
    cursor: pointer; /* 마우스 오버시 커서 변경 */
    font-size: 1em;
}

.chat-button:hover {
    background-color: #e0637c; /* 버튼 호버 배경 색상 */
}
.hamburger-menu {
    display: none; /* 기본적으로는 햄버거 메뉴를 숨깁니다 */
    border: none;
    background: none;
    cursor: pointer;
    padding: .5rem;
}

.mb-nav-menu {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%); /* 메뉴를 위로 이동시켜 숨깁니다 */
    transition: visibility 0s linear 0.5s, opacity 0.5s linear, transform 0.5s ease-out; /* transform 애니메이션 추가 */
    background-color: #e9e9e9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed; /* 왼쪽에서 슬라이드되도록 위치 조정 */
    width: 250px; /* 메뉴 너비 */
    transform: translateX(-100%); /* 메뉴를 왼쪽으로 숨김 */
    transition: transform 0.5s ease-out;
    top: 0px;
    height: 100vh;
    z-index: 11;
}

.mb-nav-menu.open {
    visibility: visible;
    opacity: 1;
    transform: translateX(0%); /* 메뉴를 원래 위치로 되돌립니다 */
    transition-delay: 0s; /* 트랜지션 지연 시간을 0으로 설정 */
}
.mb-nav-menu img {
    height: 60px;
    margin-left: 2.5rem;
}
.mb-nav-menu > .nav-logo {
    display: flex;
    align-items: center;
    margin: 1rem 0;
}
.mb-nav-menu ul {
    padding: 10px;
    margin: 0;
    margin-top: 3rem;
}
.mb-nav-menu li {
    color: black; /* 글자색을 검은색으로 설정 */
    text-align: center; /* 텍스트를 중앙으로 정렬 */
    list-style: none; /* 리스트 스타일 제거 */
    margin-top: 20px;
}

.mb-nav-menu li a:hover {
    color: #F07489;
    transition: .1s;
}

.mb-nav-menu li a {
    text-decoration: none; /* 하이퍼링크의 밑줄 제거 */
    color: black; /* 하이퍼링크의 글자색을 검은색으로 설정 */
    display: block; /* 링크를 블록 요소로 만들어 전체 영역이 클릭 가능하게 함 */
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 투명한 회색 */
    z-index: 9; /* 네비게이션 바 바로 아래 */
}
.overlay.show {
    display: block;
}

@media screen and (max-width: 768px){
    .hamburger-menu {
        display: block; /* 모바일 화면에서만 햄버거 메뉴 버튼을 표시합니다 */
    }
    .logo-name-s {
        font-size: .65rem !important;
        margin-left: .1rem;
    }
    .logo-name-l {
        font-size: 1.5rem !important;
    }
    .nav-menu {
        display: none;
    }
    .nav-wrapper > .nav-logo > img {
        margin-right: 10px;
    }
}

/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */
/* MainForm */

.main-container {
    display: flex; /* flex 컨테이너로 만들기 */
    flex-direction: column; /* 아이템들을 세로로 정렬 */
    align-items: center; /* 가로축을 중심으로 중앙 정렬 */
    justify-content: flex-start; /* 세로축을 중심으로 중앙 정렬 */
    height: 100%; /* 컨테이너 높이를 브라우저 창 높이와 동일하게 설정 */
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    overflow: hidden;
    position: relative;
}

.img-container > img {
    max-height: 100%;
    width: auto;
    object-fit: cover;
}

.carousel-buttons {
    position: absolute; /* 이미지 컨테이너 내부에 절대 위치 */
    bottom: 10px; /* 하단에서 10px 위에 위치 */
    left: 50%; /* 중앙 정렬 */
    transform: translateX(-50%); /* X축으로 -50% 만큼 이동하여 중앙 정렬 */
    display: flex;
}

.carousel-buttons button {
    width: 20px; /* 버튼 너비 조정 */
    height: 10px; /* 버튼 높이 조정 */
    margin: 0 5px;
    background-color: white; /* 기본 버튼 색상 */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.carousel-buttons button.active {
    background-color: pink; /* 활성화된 버튼 색상 */
}

.catchp {
    margin-top: 2rem;
    font-size: 1.2em;
    text-align: center; /* 텍스트 중앙 정렬 */
    font-family: 'TTHakgyoansimMonggeulmonggeulR' !important;
}
.catchp p {
    margin: .5rem 0;
    font-family: 'TTHakgyoansimMonggeulmonggeulR';
}

.bubbleIcon {
    width: 50px;
    height: 50px;
    margin: 0 10px;
    color: #F07489;
    background-color: white;
}

/* main2 */
.rendered-name{
    position: absolute;
    top : 35%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 9rem;
    font-family: Dovemayo_gothic;
    text-align: center;
}

.rendered-name .para {
    margin-top: 3rem;
    font-size: 2rem;
    text-align: center;
}

/*.start {
    display: none;
}*/

.start {
    display: inline-block;
    background-color: #F07489;
    border-radius: 15px;
    padding: .5rem;
    font-size: 2rem;
    color: white;
    text-align: center;
}

.chatbot{
    position: absolute;
    top : 86%;
    left: 90%;
    transform: translateX(-50%);
    color: white;
    font-size: 1rem;
    font-family: Dovemayo_gothic;
    text-align: center;
}
.chatbot img {
    height: 45px;
    background-color: white;
    border-radius: .7rem;
}

@media screen and (max-width: 768px){
    .rendered-name{
        font-size: 6rem;
    }
    .rendered-name .para {
        font-size: 1.1rem;
        font-weight: bold;
        margin-left: 1rem;
        margin-top: 2rem;
    }
    .start {
        display: inline-block;
        background-color: #F07489;
        border-radius: 15px;
        padding: .5rem;
        font-size: 2rem;
        color: white;
        text-align: center;
    }
}

@media screen and (max-width: 400px){
    .rendered-name{
        position: absolute;
        top : 30%;
    }
    .rendered-name .para {
        font-size: .9rem;
        font-weight: bold;
        margin-left: 0rem;
        margin-top: 1rem;
    }
    .rendered-name{
        font-size: 4.5rem;
    }
    .start {
        font-size: 1.5rem;
        color: white;
        text-align: center;
    }
    .nav-logo img {
        height: 45px!important;
    }
    .nav-wrapper {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}


/* 우측 하단 고정 버튼 컨테이너 */
.fixed-buttons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 1000; /* 항상 위에 표시 */
  }
  
  /* 각 버튼 스타일 */
  .fixed-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #7e3b98;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
  }
  
  /* 버튼 hover 효과 */
  .fixed-button:hover {
    background-color: #5a2973;
    transform: scale(0.9);
  }
  
  /* 개별 버튼 위치 조정 (필요시) */
  .button-1 {
    background-color: #ffcc00; /* 버튼 1 배경색 */
  }
  
  .button-2 {
    background-color: #7e3b98; /* 버튼 2 배경색 */
  }
  
  .fixed-button img {
    width: 30px;
    height: 30px;
  }
  
  @media (max-width: 485px) {
    .fixed-button {
      width: 40px;
      height: 40px;
    }
  }