/* 프로필 페이지 컨테이너 기본 스타일 */
.profile-page-container {
    max-width: 600px;
    margin: 1rem auto;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
}

.profile-page-container > form {
    margin: 10px auto;
}

/* 프로필 헤더 스타일 */
.profile-header {
    text-align: center;
    margin-bottom: 10px;
}

.profile-header img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

/* 파일 업로드 래퍼 스타일 */
.file-upload-wrapper {
    cursor: pointer;
    background-color: #f0f0f0;
    border: dashed 2px #ddd;
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    width: 95.5%;
    height: 3rem;
}

.file-upload-content {
    color: #888;
    font-size: 20px;
    margin-top: .8rem;
}

/* 입력 필드 스타일 */
.profile-header > input[type="text"], textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

textarea {
    height: 100px;
    resize: vertical;
}

/* 버튼 스타일 */
.profile-submit {
    width: 100%;
    background-color: #F07489;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Dovemayo_gothic';
}

.profile-submit:hover {
    background-color: pink;
}

.profile-page-container .error {
    position: fixed; /* 팝업을 화면에 고정 */
    top: 20%; /* 상단에서부터의 위치 */
    left: 50%; /* 왼쪽에서부터의 위치 */
    transform: translate(-50%, -50%); /* 정확한 중앙 위치 조정 */
    z-index: 1000; /* 다른 요소들 위에 표시 */
    background-color: rgba(0, 0, 0, 0.7); /* 반투명 검정색 배경 */
    color: #fff; /* 텍스트 색상 */
    padding: 20px 40px; /* 내부 여백 */
    border-radius: 5px; /* 모서리 둥글게 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
    font-size: 1rem; /* 폰트 크기 */
    text-align: center; /* 텍스트 중앙 정렬 */
    max-width: 80%; /* 최대 너비 설정 */
    word-wrap: break-word; /* 긴 텍스트 처리 */
  }

@media (max-width: 768px) {
    .file-upload-content {
        font-size: 15px;
    }

    .file-upload-wrapper {
        width: 93%;
    }
    .profile-page-container .error {
        width: 60%;
    }
    
}
@media (max-width: 400px) {
    .profile-page-container {
        margin: 0 auto;
    }
    .profile-submit {
        font-size: .9rem;
    }
    .file-upload-content {
        font-size: .9rem;
    }
}
