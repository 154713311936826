@font-face {
  font-family: 'Dovemayo_gothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2302@1.1/Dovemayo_gothic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
font-family: Dovemayo_gothic, Helvetica, Arial, sans-serif;
font-size: 1.2rem;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
color: #2c3e50;
animation-fill-mode: forwards;
}

/*body { overflow: hidden; }*/
body * { overflow-x: clip; }

body::-webkit-scrollbar {
  display: none;
}

li {
  list-style: none;
}
a {color: #2c3e50; text-decoration: none; outline: none}

/* 글자편의용 */
.color {
  color: #F07489;
}

.bold {
  font-family: ugro-bold, Helvetica, Arial, sans-serif;
}

p {
  margin: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;

  }
  to {
    opacity: 0;
  }
}

