/*공통*/
textarea {
    font-family: 'dovemayo_gothic';
}

/* CMainForm */
/* CMainForm */
/* CMainForm */
/* CMainForm */
/* CMainForm */
/* CMainForm */


.App{
    max-height: 400px;  /* 스크롤이 필요한 최대 높이를 설정 */
    overflow-y: auto; 
}

.commu-container {
    max-width: 800px; /* 최대 너비 설정 */
    width: 100%; /* 너비를 100%로 설정하여 부모 컨테이너에 맞춤 */
    margin: 0 auto; /* 중앙 정렬 */
    padding: 20px; /* 내부 여백 */
}

.commu-container__board-title {
    /*하단 밑줄*/
    border-bottom: 1px solid #333;
    padding-bottom: 5px;
    width: 100%;
}
/* 글 쓰기 버튼 고정 및 중앙 정렬 */
.commu-container__create-link {
    position: fixed;
    bottom: 30px;
    left: 0;
    right: 0;
    width: 130px; /* 버튼의 너비 */
    margin: 0 auto; /* 중앙 정렬 */
    padding: 10px 0;
    background-color: #e2e2e2;
    color: black;
    border-radius: 25px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    z-index: 1000; /* 다른 요소 위에 표시되도록 z-index 설정 */
    text-decoration: none;
}
.commu-container__create-link > img {
    height: 25px;
    vertical-align: middle;
}

/* 버튼 호버 효과 */
.commu-container__create-link:hover {
    background-color: #F07489;
    /*천천히 색상 변화*/
    transition: background-color 0.5s;
    color: white;
}


/* 통합 게시판 제목 */
.commu-container__board-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: bold;
}

/* 게시물 리스트 */
.commu-container__post-list {
    list-style: none;
    padding: 0;
}
.commu-container__post-list-item-title {
    margin-right: auto!important;
    font-weight: bold;
    margin-bottom: .5rem;
    font-size: 1rem;
}
.commu-container__post-list-item-type {
    margin-right: auto;
    background-color: white;
    padding: 0;
    color: #94969B;
    font-size: 1rem;
}
.commu-container__post-list-item-content {
    margin-right: auto;
    font-size: 1rem;
}
.commu-container__post-list-item-i {
    display: flex;
    margin-top: .5rem;
}
.commu-container__post-list-item-i-view {
    display: flex;
    margin-right: 2rem;
}
.commu-container__post-list-item-i-view > img{
    height: 16px;
    margin-right: .5rem;
}
.commu-container__post-list-item-i-comment {
    display: flex;
}
.commu-container__post-list-item-i-comment > img{
    height: 16px;
    margin-right: .5rem;
}
.commu-container__post-list-item-date {
    font-size: 1rem;
    color: #94969B;
    margin-right: 5px;
}

.commu-container__post-list-item {
    border-bottom: 1px solid #e2e2e2;
    width: 100%;
    padding: 15px 0;
}
.commu-container__post-list-item:hover {
    background-color: #f9f9f9;
    transition: background-color 0.5s;
}

.commu-container__post-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #333;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
}
.type-select {
    width: 100%;
    height: 46px;
    padding-left: 15px;
    border: 1px solid #d4d4d4;
    background-color: #fff;
    font-size: 1.2rem;
    font-family: 'dovemayo_gothic';
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../static/img/down-arrow.png');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    padding-right: 30px;
}
.date-school {
    display: flex;
    margin-top: 1rem;
    color: #94969B;
}
.commu-container__post-list-item-date-school {
    color: #F07489;
    font-size: 0.8rem;
    margin: 2px;
}

@media (max-width: 768px) {
    .commu-container__board-title {
        font-size: 1rem;   
        width: 90%;
    }
    .commu-container__post-list-item {
        font-size: .8rem;
        width: 90%;
    }
    .commu-container__post-list-item-type {
        margin-right: auto;
        background-color: white;
        padding: 0;
        color: #94969B;
        font-size: 0.9rem;
    }
    .commu-container__post-list-item-title {
        margin-right: auto;
        font-size: .9rem;
    }
    .commu-container__post-list-item-content {
        font-size: 0.875rem;
    }
    .commu-container__post-list-item-date {
        font-size: 0.85rem;
    }
    .commu-container__post-link {
        flex-direction: column;
    }
    .commu-container__create-link {
        font-size: 1rem;
    }
    .type-select {
        width: 90%;
        font-size: 1rem;
    }

}

/* CDetailForm.jsx */
/* CDetailForm.jsx */
/* CDetailForm.jsx */
/* CDetailForm.jsx */
/* CDetailForm.jsx */
/* CDetailForm.jsx */
/* CDetailForm.jsx */


.post-detail-container {
    max-width: 800px;
    width: 100%;
    margin: auto;
    padding: 20px;
}
.post-detail-container__copylink {
    color: #666;
    font-size: 0.75rem;
    /*오른쪽 정렬*/
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
}
.post-detail-container__copylink:hover {
    color: #F07489;
}
.post-detail-container__title {
    font-size: 1.5rem;
    font-weight: bold;
}
.post-detail-container__image {
    width: 100%;
}
.post-detail-container__image img {
    width: 89%;
    margin-bottom: 1rem;
}
.post-detail-container__content {
    margin-top: 1.5rem;
}
.post-detail-container__content > p {
    width: 100%;
    white-space: pre-wrap;
}
.post-detail-container__i {
    display: flex;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 1.5rem;
    font-size: 0.9rem;
    margin-top: .5rem;
}
.post-detail-container__i-view {
    display: flex;
    margin-right: 2rem;
}
.post-detail-container__i-comment {
    display: flex;
}
.post-detail-container__i img{
    height: 20px;
    vertical-align: middle;
    margin-right: .5rem;
}
.post-detail-container__writer {
    display: flex;
    margin-top: .5rem;
}
.post-detail-container__writer-school{
    color: #37ACC9;
    margin-right: 5px;
    font-size: 0.9rem;
}
.post-detail-container__writer-username {
    font-size: 0.9rem;
    color: #666;
}
.post-detail-container__writer-username-checkiswriter {
    font-size: 0.8rem;
    color: #F07489;
    margin-left: 1rem;
}
.post-detail-container__comment-time {
    font-size: 0.75rem;
    color: #94969B;
}

/* 게시글 본문 */
.post-detail-container p {
  line-height: 1.6;
}

/* 사용자 정보 - 학교 이름, 사용자 이름 */
.commu-container__post-list-item-school,
.commu-container__post-list-item-writer {
  margin-top: 5px;
  font-weight: bold;
}

/* 댓글 섹션 */
.comments-section {
  margin-top: 30px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.comment-item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.comment-item > p {
    white-space: pre-wrap;
}

/* 댓글 입력 폼 */
.comment-form {
  margin-top: 20px;
}

.comment-textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100px;
  resize: none;
}

.comment-submit-btn {
  background-color: #F07489;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.comment-submit-btn:disabled {
  background-color: #ccc;
}
.alert-comment {
    color: #666;
    font-size: 0.85rem;
}
.alert-comment > a {
    color: #F07489;
}

@media (max-width: 768px) {
    .post-detail-container__title {
        width: 90%;
    }
    .post-detail-container__i {
        width: 90%;
    }
    .comment-textarea {
        width: 90%;
    }
    .comments-section {
        width: 90%;
    }
    .post-detail-container__title {
        font-size: 1.25rem;
    }
    .post-detail-container__content > p {
        width: 90%;
    }
    .post-detail-container__writer-school {
        font-size: 0.75rem;
    }
    .post-detail-container__writer-username {
        font-size: 0.75rem;
    }
    .post-detail-container__content {
        font-size: 0.95rem;
    }
    .comment-item > p {
        font-size: 0.875rem;
    }
    .post-detail-container__copylink {
        padding-right: 3rem;
    }
    .post-detail-container__comment-time {
        font-size: 0.65rem!important;
    }
}
  


/* create form */
/* create form */
/* create form */
/* create form */
/* create form */
/* create form */
/* create form */
/* create form */


.commu-create-container__form {
    max-width: 800px;
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
}

.commu-create-container__form__total {
    border: 1px solid #ccc;
}
.commu-create-container__title {
    text-align: center;
}
.commu-create-container__form-group {
    border-bottom: 1px solid #ccc;
}

.commu-create-container__form-input,
.commu-create-container__form-textarea,
.commu-create-container__form-select {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border: none;
}

.commu-create-container__form-textarea {
    height: 300px;
    margin: 0;
}
.submit-and-image {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.commu-create-container__form-submit {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
}
.commu-create-container__form-submit img {
    height: 30px;
    align-items: center;
    justify-content: center;
    
    /*div내 이미지 중앙정렬*/

}

.commu-create-container__form-submit:hover {
    background-color: pink;
}

.commu-create-container__form-group.content {
    white-space: pre-wrap;
}
.commu-create-container__form-group.images {
    margin-top: .5rem;
    display: flex;
    flex-wrap: nowrap;
    gap: 10px; 
    /*가로 스크롤바 */
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 0;
}
.commu-create-container__form-group.images img {
    height: 100px;
    border-radius: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    object-fit: cover;
}

/* 이미지 프리뷰 삭제 */
.image-preview-container {
    position: relative;
    flex: 0 0 auto;
    margin-right: 10px;
}
.remove-image-icon > img {
    height: 30px!important;
}

.remove-image-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: none;
}

.image-preview-container:hover .remove-image-icon {
    display: block;
}
.hidden-btn {
    display: none;
}
.image-input {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-input img {
    height: 30px;
    margin-left: 5px;
}


@media (max-width: 768px) {
    .commu-create-container__form__total{
        width: 90%;
    }
    .commu-create-container__title {
        display: none;
    }
    .commu-create-container__form-group.images {
        width: 90%;
    }
}
@media (max-width: 400px) {
    .commu-create-container__form {
        margin-left: -5px;
    }
    .commu-container .online-user {
        margin-right: 3.5rem;
    }
}

.commu-container__post-list .advertise{
    background-color: #ffd6da;
    border-radius: 1rem;
    margin-right: 10rem;
    width: 96%;
    padding: 10px;
}
.commu-container__post-list .advertise p{
    background-color: #ffd6da;
}

.commu-container__post-list .manager-notice{
    background-color: #d1eaf5;
    border-radius: 1rem;
    margin-right: 10rem;
    width: 96%;
    padding: 10px;
}
.commu-container__post-list .manager-notice p{
    background-color: #d1eaf5;
}
